@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Palanquin&display=swap');

body {
  font-family: 'Roboto', cursive;
}

.animate {
  animation-duration: 0.75s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  animation-fill-mode: backwards;
}

.animate.fade {
  animation-name: animate-fade;
  animation-timing-function: ease;
  animation-delay: 0s;
}

.servirus {
  font-family: 'Palanquin', serif;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate.pop {
  animation-name: animate-pop;
  animation-duration: 1s;
  animation-delay: 0.4s;
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 90%;
  margin-bottom: -24px;
  object-fit:contain ;
}

.swiper-wrapper{
  height: 90%;
}
.swiper-button-next:hover, .swiper-button-prev:hover, .swiper-pagination-bullet:hover {
  transform: scale(1.5, 1.5);
  transition-duration: 100ms;
}
